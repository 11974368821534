@font-face {
	font-family: "Suisse Intl";
	src: url("./assets/fonts/SuisseIntl-Regular.eot");
	src:
	url("./assets/fonts/SuisseIntl-Regular.eot") format("embedded-opentype"),
	url("./assets/fonts/SuisseIntl-Regular.woff2") format("woff2"),
	url("./assets/fonts/SuisseIntl-Regular.woff") format("woff"),
	url("./assets/fonts/SuisseIntl-Regular.ttf") format("truetype"),
	url("./assets/fonts/SuisseIntl-Regular.svg") format("svg");
}
// libs
@import 'bootstrap/dist/css/bootstrap.min.css';
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "react-input-range/lib/css/index.css";
// elements
@import './assets/scss/_variables.scss';
@import './assets/scss/_animation.scss';
@import './assets/scss/_navbar.scss';
@import './assets/scss/_create-form.scss';
@import './assets/scss/_footer.scss';
@import './assets/scss/_preloader.scss';
// pages
@import './assets/scss/_home.scss';
@import './assets/scss/_documentation.scss';
@import './assets/scss/_community.scss';