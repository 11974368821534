.create-form {
    position: relative;
    font-family: "Suisse Intl";
    background-color: #ececec;
    z-index: 3;
    height: 100vh;
    transition: opacity .5s ease-in-out;
    .redirect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5vh;
        padding: 10px 24px;
        transition: opacity .5s ease-in-out;
        &.hidden {
            opacity: 0;
        }
    }
    &_close-form{
        z-index: 6;
        cursor: pointer;
        position: absolute;
        right: 21px;
        top: 9px;
    }
    &_hidden{
        opacity: 0;
        height: 0vh;
        overflow: hidden;
    }
    &_topbar {
        cursor: pointer;
        display: none;
    }
    &_step-1 {
        z-index: 4;
    }
    &_step-2 {
        z-index: 5;
    }
    &_step-3 {
        z-index: 5;
    }
    &_step-4 {
        z-index: 7;
    }
    &_step {
        position: absolute;
        border-radius: 32px 32px 0 0;
        background-color: white;
        display: flex!important;
        height: 95vh;
        width: 100% !important;
        margin: 0 !important;
        top: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // transform: translateY(-100%);
        &.create-form_step-4 {
            height: 0;
            opacity: 0;
            border-radius: 0;
            background-color: $primary-color;
        }
        transition: all .5s ease-in-out;
        .flex-wrapper {
            padding: 20px 25px 0 25px;
            display: flex;
            height: 85%;
            flex-wrap: wrap;
        }
    }    
    &_active{
        top: 5vh;
        &.create-form_step-4 {
            top: 0;
            height: 100vh;
            opacity: 1;
        }
        .next-btn {
            position: fixed;
            // bottom: 0;
            left: 0;
            width: 100%;

            top: 100vh;
            transform: translateY(-100%);
        }
    }
    &_last-active{
        top:0vh;
        background-color: #ececec;
        .create-form_topbar{
            display: block;
            color: #979797;
            font-size: 1.2em;
            line-height: 5vh;
            padding-left: 30px;
        }
    }  

    &_left-side {
        color: #EDEDED;
        h2{
            font-size: 6.8em;
            padding-left: 0px;        
        }
    }
    &_right-side {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 40px;
        overflow-y: auto;
        h1{
            color: #000000;
            font-size: 1.5em;
        }
        p{
            color: #818181;
        }
        .input-icon {
            font-size: 1.8em;
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translate(-50%, -50%);
        }
    }
    .next-btn {
        z-index: 10;
    }
    button{
        background-color: #13AF72;
        border: 0;
        border-radius: 0;
        height: 50px;        
    }
    input{
        background-color: #f5f5f7;
        border-radius: 36px;
        padding:20px 30px;
        margin: 10px 0;
        border: 0;
        &:focus{
            background-color: #e4e4e4;
            outline: 0;
            box-shadow: none;
        }
    }

    .question-wrapper {
        position: absolute;
        z-index: 1;
        height: 22px;
        width: 22px;
        display: inline;
        top: 6px;
        .question-icon {
            color: #0B6944;
            z-index: 2;
        }
        .question-title {
            display: none;
            bottom: 18px;
            left: 12px;
            border-radius: 8px;
            padding: 8px;
            background-color: #000;
            color: #999999;
            font-size: 12px;
            width: 382px;
            position: absolute;
            opacity: 0;
            transition: opacity .5s ease;
            &.visible {
                display: block;
                opacity: 1;
            }
        }
        &.fr {
            left: 100px;
        }
        &.sc {
            left: 190px;
        }
    }
    
    .input-range-label {
        position: absolute;
        display: flex;
        justify-content: space-between;
        z-index: 1;
        width: 100%;
        padding: 0px 30px;
        pointer-events: none;
        top: 1.8rem;
        transform: translate(0, -50%);
        color: #0B6944;
        font-size: 1.2em;
        .percent {
            color: #000000;
        }
    }
    .input-range {
        height: 4rem;
        margin: 10px 0;
        .input-range__track--background {
            border-radius: 36px;
            overflow: hidden;
            top: 0;
            height: 100%;
            .input-range__track--active {
                height: 100%;
                border-radius: 0;
                background-color: $primary-color;
            }
            .input-range__slider-container {
                .input-range__slider {
                    z-index: 1;
                    top: 0;
                    height: 4rem;
                    transform: translate(50%,-100%);
                    margin-bottom: 0;
                    margin-top: 0;
                    width: 2.5rem;
                    right: 0;
                    border: unset;
                    background: url('../img/icons/slider_arrow.svg');
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                .input-range__label.input-range__label--value {
                    display: none;
                }
            }
        }
        .input-range__label.input-range__label--min, .input-range__label.input-range__label--max {
            display: none;
        }
    }
    .vote-period-wrapper {
        .question-icon {
            position: absolute;
            right: -24px;
            top: -5px;
            color: #999999;
        }
        .vote-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            margin: 10px 0;
            .vote-period-label {
                color: #818181;
            }
            .vote-period {
                display: flex;
                width: 50%;
                margin-bottom: 0 !important;
                .field {
                    &:nth-child(1) {
                        input {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }
                    &:nth-child(2) {
                        input {
                            border-radius: 0;
                            border-left: 2px solid rgba(0,0,0,.1);
                            border-right: 2px solid rgba(0,0,0,.1);
                        }
                    }
                    &:nth-child(3) {
                        input {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }
                    input {
                        margin-top: 0 !important;
                        margin-bottom: 0 !important;
                        &:focus {
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
    .label-before {
        font-size: 0.75em;
        top: 15px;
        left: 30px;
    }
    .field {
        position: relative;
        label::before {
            content: attr(title);
            position: absolute;
            top: 25px;
            left: 30px;
            color: #777;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            transition: 300ms all;
        }
        input, textarea {
            width: 100%;
            background-color: #f5f5f7;
            border-radius: 36px;
            padding: 30px 30px 10px 30px;
            margin: 5px 0;
            border: 0;
            &:focus{
                background-color: #e4e4e4;
                outline: 0;
                box-shadow: none;
            }
            &:valid + label::before {
                @extend .label-before;
                content: attr(data-title);
            }
            &:focus + label::before {
                @extend .label-before;
            }
        }
        textarea {
            resize: none;
        }
    }
    .create-status {
        padding: 40px;
        font-size: 4em;
        line-height: 1.2em;
        letter-spacing: 0.02em;
        color: #F5F5F7;
        display: flex;
        .title {
            z-index: 1;
            display: flex;
            flex-direction: column;
            .dark {
                color: rgba(245,245,247,.5)
            }
            .daoLink {
                display: none;
                a {
                    text-decoration: none;
                    color: rgba(245,245,247,.5)
                }
            }
        }
        .percentage {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 100%;
            background-color: #0DA569;
            display: flex;
            align-items: flex-end;
            padding: 40px;
            transition: width 1s ease;
        }
    }
}

@media(max-width: 991px) {
    .create-form {
        &_right-side {
            height: 70vh;
        }
        .question-wrapper {
            &.fr {
                left: 97px;
            }
            &.sc {
                left: 183px;
            }
        }
    }
}

@media(max-width: 721px) {
    .create-form {
        .vote-period-wrapper {
            .vote-row {
                flex-direction: column;
                align-items: flex-start;
                .vote-period {
                    width: 100%;
                }
            }
        }
        .question-wrapper {
            &.fr {
                left: 89px;
            }
            &.sc {
                left: 166px;
            }
        }
    }
}

@media(max-width: 610px) {
    .create-form {
        .question-wrapper {
            .question-title {
                display: none;
                width: 224px;
                left: 12px;
                bottom: 23px;
                &.visible {
                    display: block;
                }
            }
        }
    }
}