﻿@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}
.page-footer {
  padding: 4.06vw 17.5vw 2.08vw 14.5vw;
  background-color: #94ffd5;
  animation: fadein 2s;
  .container {
    display: flex;
    justify-content: space-between;
    .links-wrapper {
      display: flex;
      .links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 5.2vw;
        &:last-child {
          padding-right: 0;
        }
        .redirect {
          text-decoration: none;
          padding-top: 0.52vw;
          font-size: 1.2em;
          line-height: 1.4em;
          color: #000000;
          text-shadow: 0px 12px 64px rgba(0, 0, 0, 0.15),
            0px 0px 4px rgba(0, 0, 0, 0.05);
        }
        .rights {
          padding-top: 5.2vw;
          font-size: 1.2em;
          line-height: 1.2em;
          color: rgba(0, 0, 0, 0.4);
          &.mobile {
            display: none;
          }
        }
        .white-space.mobile {
          display: none;
        }
      }
    }
    .footer-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .white-space {
        width: 100%;
        height: 3px;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

@media (max-width: 414px) {
  .page-footer {
    height: 90vh;
    padding: 0;
    background: linear-gradient(0deg, $primary-color, #ffffff);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .container {
      padding: 0 27px 45px 27px;
      .links-wrapper {
        width: 100%;
        justify-content: space-between;
        .links {
          &:last-child {
            padding-right: 30px;
          }
          .rights {
            display: none;
            &.mobile {
              display: block;
            }
          }
          .white-space.mobile {
            display: block;
            width: 30px;
            height: 1px;
            background: rgba(0, 0, 0, 0.4);
          }
          .redirect {
            font-size: 16px;
            line-height: 21px;
            color: #000000;
            text-shadow: 0px 12px 64px rgba(0, 0, 0, 0.15),
              0px 0px 4px rgba(0, 0, 0, 0.05);
            font-weight: 500;
            padding: 10px 0;
          }
        }
      }
      .footer-icon {
        display: none;
      }
    }
  }
}
