﻿@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 0.9; }
}
.page-header-wrapper {
  position: fixed;
  z-index: 2;
  width: 100%;
  opacity: 0.9;
  animation: fadein .5s;
}
.page-header {
  font-size: 1.2em;
  line-height: 1.2em;
  background-color: transparent;
  padding: 2.3vw 0;
  a {
    color: #000000;
    text-decoration: none;
  }
  .weezi-logo {
    height: 16px;
    margin: 0 0 2px 4px;
  }
  .redirect {
    position: absolute;
    top: 2.3vw;
    &:first-child {
      left: 1.3vw;
    }
    &:last-child {
      right: 2.7vw;
      color: $primary-color;
    }
  }
  .routes {
    display: flex;
    position: relative;
    margin-left: 17.8vw;
    .route {
      display: block;
      color: rgba(0, 0, 0, 0.4);
      &.active {
        color: #000000;
      }
      padding-right: 2.2vw;
      &:last-child {
        padding-right: 0;
      }
      &:hover {
        color: $primary-color;
      }
    }
    &.mobile {
      display: none;
    }
  }
}
.topbar-menu-icon {
  transform: rotate(0deg);
  transition: transform 0.3s ease 0s;
  &.active {
    transform: rotate(225deg);
    transition: transform 0.3s ease 0s;
  }
}

@media (max-width: 479px) {
  // .page-header .routes {
  // 	display: none;
  // }
  .page-header .routes .route {
    padding-right: 20px;
  }
}

@media (max-width: 414px) {
  .page-header-wrapper {
    opacity: 1;
  }
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 29px 27px;
    .routes {
      display: none;
      &.mobile {
        margin: 0;
        display: block;
        .route {
          font-size: 28px;
          line-height: 34px;
          color: #000000;
          padding: 10px 0;
          font-weight: 500;
        }
        .bm-overlay {
          margin-top: 5px;
          left: 0;
          background: rgba(255, 255, 255, 1) !important;
        }
        .bm-menu-wrap {
          width: 100% !important;
          left: 0;
        }
        .bm-item-list {
          padding-top: 90px;
          position: relative;
        }
        .bm-content-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .main-links {
            display: flex;
            flex-direction: column;
            padding: 0 27px;
          }
          .bottom-links {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 100px;
            width: 100%;
            padding: 0 27px;
            .link {
              text-decoration: none;
              font-size: 16px;
              line-height: 21px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
    }
    .redirect {
      &:first-child {
        position: unset;
        width: 100px;
      }
      &:last-child {
        position: unset;
        display: block;
        width: 100px;
      }
    }
  }
}
