﻿@keyframes fadeInUp_300 {
	from {
		opacity: 0;
		transform: translate3d(0, 300%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
.animate__fadeInUp_300 {
	animation-name: fadeInUp_300;
	animation-duration: 1s;
}
.animate__fadeInUp {
	animation-name: fadeInUp;
	animation-duration: 1s;
	animation-timing-function: cubic-bezier(.27,.68,.65,.99);
}
.animate__delay-50ms {
	animation-delay: 50ms;
}
.animate__delay-100ms {
	animation-delay: 100ms;
}
.animate__delay-150ms {
	animation-delay: 150ms;
}
.animate__delay-200ms {
	animation-delay: 200ms;
}
.animate__delay-250ms {
	animation-delay: 250ms;
}
.animate__delay-300ms {
	animation-delay: 300ms;
}
.animate__delay-350ms {
	animation-delay: 350ms;
}
.animate__delay-400ms {
	animation-delay: 400ms;
}
.animate__delay-450ms {
	animation-delay: 450ms;
}
.animate__delay-500ms {
	animation-delay: 500ms;
}
.animate__delay-550ms {
	animation-delay: 550ms;
}
.animate__delay-600ms {
	animation-delay: 600ms;
}
.animate__delay-650ms {
	animation-delay: 650ms;
}
.animate__delay-700ms {
	animation-delay: 700ms;
}
.animate__delay-750ms {
	animation-delay: 750ms;
}
.animate__delay-800ms {
	animation-delay: 800ms;
}
.animate__delay-850ms {
	animation-delay: 850ms;
}
.animate__delay-900ms {
	animation-delay: 900ms;
}
.animate__delay-950ms {
	animation-delay: 950ms;
}
.animate__delay-1000ms {
	animation-delay: 1000ms;
}
.animate__delay-1100ms {
	animation-delay: 1100ms;
}
.animate__delay-1150ms {
	animation-delay: 1150ms;
}
.animate__delay-1300ms {
	animation-delay: 1300ms;
}
.animate__delay-1450ms {
	animation-delay: 1450ms;
}